import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const carouselBlocks = document.querySelectorAll('.hg-related-categories');

  if (0 < carouselBlocks.length) {
    carouselBlocks.forEach((carouselBlock) => {
      const thumbsCarousel = carouselBlock.querySelector(
        '.hg-related-categories__thumbnails-carousel'
      );
      const contentCarousel = carouselBlock.querySelector(
        '.hg-related-categories__content-carousel'
      );

      if (contentCarousel) {
        const thumbs = new Swiper(thumbsCarousel, {
          slidesPerView: 1,
          rewind: true,
          a11y: {
            firstSlideMessage: _x(
              'This is the first slide',
              'carousel first slide label',
              'vtx'
            ),
            lastSlideMessage: _x(
              'This is the last slide',
              'carousel first slide label',
              'vtx'
            ),
            paginationBulletMessage: _x(
              'Go to slide {{index}}',
              'carousel navigation label',
              'vtx'
            ),
            prevSlideMessage: _x(
              'Previous slide',
              'carousel previous label',
              'vtx'
            ),
            nextSlideMessage: _x('Next slide', 'carousel next label', 'vtx'),
          },
        });
        const contents = new Swiper(contentCarousel, {
          slidesPerView: 1,
          rewind: true,
          controller: {
            control: thumbs,
          },
          pagination: {
            el: contentCarousel.querySelector('.swiper-pagination'),
            clickable: true,
          },
          a11y: {
            firstSlideMessage: _x(
              'This is the first slide',
              'carousel first slide label',
              'vtx'
            ),
            lastSlideMessage: _x(
              'This is the last slide',
              'carousel first slide label',
              'vtx'
            ),
            paginationBulletMessage: _x(
              'Go to slide {{index}}',
              'carousel navigation label',
              'vtx'
            ),
            prevSlideMessage: _x(
              'Previous slide',
              'carousel previous label',
              'vtx'
            ),
            nextSlideMessage: _x('Next slide', 'carousel next label', 'vtx'),
          },
        });

        contents.on('slideChange', (swiper) => {
          const currentSlide = swiper.slides[swiper.activeIndex];
          const color = currentSlide.getAttribute('data-color');
          swiper.el.style.backgroundColor = color;
        });

        thumbs.controller.control = contents;
      }
    });
  }
});
